import { useState, useEffect, useCallback, useReducer } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded"
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import moment from "moment"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../components/EmptyTable"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import parseQuery from "../../../functions/parseQuery"
import { useSelector } from "react-redux"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import TableHeadCell from "../../../components/TableCells/TableHeadCell"
import TableHeadSearchCell from "../../../components/TableCells/TableHeadSearchCell"
import TableHeadClientSearchCell from "../../../components/TableCells/TableHeadClientSearchCell"
import TableHeadCalendarCell from "../../../components/TableCells/TableHeadCalendarCell"
import TableBodyCell from "../../../components/TableCells/TableBodyCell"

const useStyles = makeStyles({
  root: {
    transition: "all .1s cubic-bezier(0.94, 0.05, 0.99, 0.02);",
    "&:hover": {
      transform: "scale(1.25)",
    },
  },
})

function provideCycler() {
  function* generator(val = "") {
    var mapper = new Map([
      [0, null],
      [1, "asc"],
      [2, "desc"],
    ])
    var count = 1
    while (true) {
      yield mapper.get(count)
      if (count < 2) count++
      else count = 0
    }
  }
  var it = generator()
  return function (val) {
    return it.next(val).value
  }
}

var cycle

const initialState = {
  order_debt: {
    order: null,
    arrangement: null,
  },
  penalty_debt: {
    order: null,
    arrangement: null,
  },
}

const reducerFn = (state, { column, arrangement }) => {
  switch (arrangement) {
    case "asc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case "desc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case null:
      return initialState
    default:
      throw new Error("Error in reducer")
  }
}

const DebtorsReport = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [query, setQuery] = useState({})
  const [count, setCount] = useState()
  const [tableData, setTableData] = useState([])
  const [isLoadingTable, setIsLoadingTable] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [lastClickedArrow, setLastClickedArrow] = useState()
  const [state, dispatch] = useReducer(reducerFn, initialState)
  const [page, setPage] = useState(1)
  // const userId = useSelector((state) => state.auth.userId)
  // const roleId = useSelector((state) => state.auth.roleId)
  

  const history = useHistory()

  const pageCount = 10
  let { sortby, limit, offset } = parseQuery()


  const getItems = useCallback(
    async (params = {}) => {
      let response = await axios.get(
        `${sortby === "unpaid-costs" ? "/unpaid-costs" : "/debtor"}`,
        { params }
      )
      setTableData(response)
      setCount(response.count)
      setIsLoadingTable(false)
    },
    [sortby]
  )

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/debtor-excel`)
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const filterHandler = (e, id, paramKey) => {
    const param = {}
    param[paramKey] = String(id)
    e.stopPropagation()
    getItems(param)
    setQuery(param)
    setPage(1)
  }

  const datePickerHandler = (val) => {
    getItems(val)
    setPage(1)
    setQuery(val)
  }

  useEffect(() => {
    getItems()
  }, [getItems])

  const handleChangePage = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, ...query })
      setPage(value)
    },
    [getItems, limit, query]
  )

  // const onSearch = (val) => {
  //   clearTimeout(debounce)
  //   debounce = setTimeout(() => {
  //     if (roleId === "a1ca1301-4da9-424d-a9e2-578ae6dcde02") {
  //       getTableData({
  //         limit,
  //         offset: offset ?? 0,
  //         investor_id: userId,
  //         search: val,
  //       })
  //     } else {
  //       getTableData({
  //         limit: limit ?? pageCount,
  //         offset: offset ?? 0,
  //         search: val,
  //       })
  //     }
  //   }, 300)
  // }

  const rowClickHandler = (orderId) => {
    history.push(`/home/orders/${orderId}?fines=true`)
  }

  const SorterArrow = useCallback(
    ({ column }) => {
      return state[column]?.arrangement === null ? (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer"
          classes={{ root: classes.root }}
          color="disabled"
          fontSize="medium"
          onClick={(e) => {
            if (lastClickedArrow !== e.target.closest(".arrow").id) {
              setLastClickedArrow(e.target.closest(".arrow").id)
              cycle = provideCycler()
            }
            dispatch({ column, arrangement: cycle() })
          }}
        />
      ) : state[column]?.arrangement === "desc" ? (
        <ArrowDownwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      ) : (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      )
    },
    [classes.root, state, lastClickedArrow]
  )

  useEffect(() => {
    var i, obj
    for (i in state) {
      if (state.hasOwnProperty(i) && state[i].order && state[i].arrangement) {
        obj = state[i]
      }
    }
    getItems({
      limit: pageCount,
      offset: offset ?? 0,
      [obj?.order]: obj?.arrangement,
    })
  }, [offset, state])

  return (
    <div>
      <Header title={t("debtorsReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        {/* <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoadingTable(true)
            }}
          />
        </div> */}
      </Filters>

      <div className="px-4 py-6 bg-white m-2">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeadCell row={2} title="&#8470;" />
                <TableHeadClientSearchCell
                  title="client.full_name"
                  url="client"
                  paramKey="name"
                  filter={filterHandler}
                />
                <TableHeadCalendarCell
                  title="date"
                  startKey="from_time"
                  endKey="to_time"
                  datePicker={datePickerHandler}
                />
                <TableHeadSearchCell
                  title="tariff"
                  url="tariff"
                  dataKey="tariffs"
                  paramKey="tariff"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="car.number"
                  url="car"
                  dataKey="cars"
                  paramKey="state_number"
                  filterItemKey="state_number"
                  itemKey="state_number"
                  filter={filterHandler}
                />
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="dr01"
                >
                  {t("Долг заказа")}
                  {<SorterArrow column="order_debt" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="dr02"
                >
                  {t("fine")}
                  {<SorterArrow column="penalty_debt" />}
                </TableCell>
              </TableRow>
            </TableHead>
            {tableData?.debtors?.map((item, index) => (
              <TableRow
                style={{
                  cursor: "pointer",
                  background: index % 2 === 0 && "#F6F8F9",
                }}
                className="hover:bg-gray-100"
                onClick={() => rowClickHandler(item.order_id)}
                key={index}
              >
                <TableBodyCell title={index + 1} fontWeight="600" />
                <TableCell style={{ borderRight: "1px solid #ddd" }}>
                  {item?.name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd", whiteSpace: "nowrap" }}
                >
                  {`${moment(item?.from_time).format("YY-MM-DD | HH:MM")}`}{" "}
                  &mdash;
                  {` ${moment(item?.to_time).format("YY-MM-DD | HH:MM")}`}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.tariff}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.state_number}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.order_debt)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.penalty_debt < 0
                    ? convertToInternationalCurrencySystem(
                        Math.abs(item?.penalty_debt)
                      )
                    : 0}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет отчетов"
          isActive={!isLoadingTable && !tableData?.debtors?.length > 0}
        />
        {isLoadingTable && <ReportSkeleton />}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            color="primary"
            variant="outlined"
            shape="rounded"
            count={Math.ceil(count / pageCount)}
            page={page}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  )
}

export default DebtorsReport

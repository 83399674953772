import axios from "axios"
import config from "../config/defaultSettings"
import { stackMessages } from "./stack_messages"

// axios new instance
const request = axios.create({
  baseURL: config.baseURL,
  timeout: 8000,
})

const waitingTime = 8000

// Error on connection
export const errorHandler = (error, hooks) => {
  if (error.response) {
    if (error.response.status === 401) {
    }
    if (error.response.status === 403) {
      const refresh_token = localStorage.getItem("refresh-token")
      const access_token = localStorage.getItem("token")

      if (refresh_token) {
        request
          .put("/auth/refresh", {
            refresh_token,
          })
          .then((res) => {
            const a_token = res?.data?.access_token
            const r_token = res?.data?.refresh_token
            if (a_token && r_token) {
              localStorage.setItem("token", a_token)
              localStorage.setItem("refresh-token", r_token)
              // window.location.reload()
            }
          })
          .catch(() => {
            const invalid = localStorage.getItem("invalid")
            if (!invalid) {
              localStorage.setItem("invalid", true)
              // window.location.reload()
            }
          })
      } else if (window.location.pathname !== "/auth/login" && access_token) {
        localStorage.clear()
        // window.location.reload()
      }
    }
  }
  return Promise.reject(error.response)
}

// request interceptor
request.interceptors.request.use((config) => {
  // here you can set tokens, client-ids, and other header types

  const token = localStorage.getItem("token")
  config.headers["client-id"] = process.env.CLIENT_ID
  config.headers["platform-id"] = "7d4a4c38-dd84-4902-b744-0488b80a4c01"
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => response.data, errorHandler)

const checkResAndMess = (req) => {
  return new Promise((resolve, reject) => {
    const timeOut = setTimeout(() => {
      reject("time out")
    }, [waitingTime])

    req
      .then((response) => {
        try {
          stackMessages.subscribe((mes, id) => {
            console.log(mes, id)
            const message = mes.find(
              (elm) => elm.correlation_id === response.message
            )
            if (message) {
              if (message.status_code < 300) {
                resolve({ response, message })
              } else {
                reject(message.error)
              }
              stackMessages.remove(message.id)
              stackMessages.unsubscribe(id)
              clearTimeout(timeOut)
            }
          })
        } catch (e) {
          reject(e)
        }
      })
      .catch((err) => reject(err))
  })
}

const init = {
  get(...args) {
    return request.get(...args)
  },
  post(...args) {
    return checkResAndMess(request.post(...args))
  },
  put(...args) {
    return checkResAndMess(request.put(...args))
  },
  patch(...args) {
    return checkResAndMess(request.patch(...args))
  },
  delete(...args) {
    return checkResAndMess(request.delete(...args))
  },
  post_default(...args) {
    return request.post(...args)
  },
}

export default init

import { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";

import axios from "../../utils/axios";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/index";
import CustomButton from "../../components/Buttons/index";
import Card from "../../components/Card/index";
import Form from "../../components/Form/Index";
import Input from "../../components/Input/index";
import ImageUpload from "../../components/Upload/ImgUpload";
import validateForm from "../../functions/validateForm";
import { cancelIcon, saveIcon } from "../../assets/icons/icons";
import PriceInput from "../../components/Form/input/PriceInput";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";

function ModelsCreate() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [isGettingData, setIsGettingData] = useState(false);
  const [image, setImage] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    price: undefined,
  });

  useEffect(() => {
    if (params.id) {
      getProperty(params.id);
    }
  }, []);

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true);

    await axios
      .get(`/additional-tariff/${id}`)
      .then((res) => {
        let data = {
          ...res,
        };

        setInitialValues(data);
        setImage(res.photo);
      })
      .finally(() => setIsGettingData(false));
  };

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true);
    if (params.id) {
      axios
        .put(`/additional-tariff/${params.id}`, {
          ...values,
          photo: image,
          price: +values.price.replace(/\s+/g, ""),
        })
        .then((res) => {
          console.log(res);
          history.push("/home/settings/additional-tariffs");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post("/additional-tariff", {
          ...values,
          photo: image,
          price: +values.price.replace(/\s+/g, ""),
        })
        .then((res) => {
          console.log(res);
          history.push("/home/settings/additional-tariffs");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    price: validateForm("price", t),
  });

  const routes = [
    {
      title: t("additional.tariffs"),
      link: true,
      route: "/home/settings/additional-tariffs",
    },

    {
      title: params.id ? initialValues.name : t("create"),
    },
  ];

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("additional.tariff")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() =>
                      history.push("/home/settings/additional-tariffs")
                    }
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full flex flex-col gap-4 box-border font-body text-sm">
                <Card title={t("general.information")} className="w-1/2">
                  {/* Photo */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("photo")}</div>
                    <div className="w-2/3">
                      <ImageUpload
                        onChange={(val) => setImage(val.filename)}
                        onStart={(val) => console.log(val)}
                        onFinish={(val) => console.log(val)}
                        onSuccess={(val) => console.log(val)}
                        onError={(val) => console.log(val)}
                        defaultImage={image}
                      >
                        <div className="flex flex-col items-center justify-center space-y-4">
                          <AddIcon style={{ fontSize: "200%" }} />
                          <h5>{t("select.image")}</h5>
                        </div>
                      </ImageUpload>
                    </div>
                  </div>
                  {/* Name */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("name")}</div>
                    <div className="w-2/3">
                      <Form.Item name="name" formik={formik}>
                        <Input
                          id="name"
                          type="text"
                          {...formik.getFieldProps("name")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Price */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("price")}</div>
                    <div className="w-2/3">
                      <Form.Item name="price" formik={formik}>
                        <PriceInput {...formik.getFieldProps("price")} />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  );
}

export default ModelsCreate;

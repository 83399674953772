import { useState, useEffect } from "react"
import Upload from "./Upload"
import styles from "./Upload.module.scss"
import { CircularProgress } from "@material-ui/core"
import axios from "../../utils/axios"
import VisibilityIcon from "@material-ui/icons/Visibility"
import DeleteIcon from "@material-ui/icons/Delete"
import pdfIcon from "../../assets/icons/pdf.png"
import wordIcon from "../../assets/icons/word.png"

export default function ImgUpload({
  onChange = () => {},
  onStart = () => {},
  onFinish = () => {},
  onError = () => {},
  onSuccess = () => {},
  defaultImage,
  shape,
  children,
  acceptImgs = ["png", "jpeg", "webp", "svg+xml"],
  action = "/upload",
  maxSize,
  minSize,
  documentsPage,
  dispatchError,
  tab,
  ...props
}) {
  const [loading, setLoading] = useState(null)
  const [imgUrls, setImgUrls] = useState(
    defaultImage && defaultImage.length
      ? defaultImage.map(
          (imgFilename) => `https://cdn.car24.uz/car24/${imgFilename}`
        )
      : []
  )
  const [images, setImages] = useState([])

  useEffect(() => {
    if (images && images.length)
      onChange((prev) => {
        if (prev) {
          return [...prev, ...images]
        }
        return [...images]
      })
  }, [images])

  const onFileDrag = (files) => {
    setLoading(true)
    files.forEach((file) => {
      if (props.multiple) {
        onStart(file)
        const formData = new FormData()
        formData.append("file", file)
        axios
          .post_default(action, formData)
          .then((res) => {
            setImgUrls((prev) => [...prev, "https://" + res.file_url])
            setImages((prev) => [...prev, res.filename])
            onSuccess(res)
          })
          .catch((err) => onError(err))
          .finally(() => {
            setLoading(false)
            onFinish("finished")
          })
      }
    })
  }

  const onImgDelete = (event, index) => {
    event.stopPropagation()
    setImgUrls((prev) => prev.filter((img, i) => i !== index))
    onChange((prev) => prev.filter((img, i) => i !== index))
  }

  const Img = ({ types }) => {
    return (
      <>
        {imgUrls.map((url, i) => (
          <div className={styles.imgContent} key={i} id={"img" + i}>
            <img
              alt="uploaded file"
              src={
                url.includes(".pdf")
                  ? pdfIcon
                  : url.includes(".doc") || url.includes(".docx")
                  ? wordIcon
                  : url
              }
              style={{
                borderRadius: shape === "rounded" ? "50%" : "6px",
                height: "30%",
                width: shape === "rounded" && "50px",
                objectFit: "cover",
              }}
            />
            <div
              className={styles.imgButtons}
              style={{
                borderRadius: shape === "rounded" ? "50%" : "6px",
                height: shape === "rounded" && "155px",
                width: shape === "rounded" && "155px",
              }}
            >
              <VisibilityIcon
                style={{ color: "#fff", fontSize: 20 }}
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(imgUrls[i], "_blank")
                }}
              />
              <DeleteIcon
                style={{ color: "#fff", fontSize: 20, marginLeft: 10 }}
                onClick={(e) => onImgDelete(e, i)}
              />
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Upload
        multiple={false}
        onChange={onFileDrag}
        accept={acceptImgs.map((val) => "image/" + val)}
        maxSize={maxSize}
        minSize={minSize}
        shape={shape}
        {...props}
      >
        {loading ? <CircularProgress size={30} /> : children}
      </Upload>
      <Img types={acceptImgs} />
    </>
  )
}
